<script>
import { required, url } from 'vuelidate/lib/validators';
import { timecode } from '@shared/config/vuelidate';
import ContentBlockWithUploadMixin from '@shared/mixins/ContentBlockWithUpload';
import ContentBlockVideoView from '@shared/components/ContentBlocks/Views/Video.vue';
import VueSimpleEmbed from '@shared/components/VueSimpleEmbed.vue';
import VideoTipsToggle from '@shared/components/VideoTipsToggle.vue';
import ContentBlockPartialTimecodes from '@shared/components/ContentBlocks/ContentBlockPartialTimecodes.vue';

const messages = {
  PERCENT: `
    <div class="content">
      <p>
        Le fichier dépasse 512 Mo (trop volumineux).
      </p>
      <p>
        Vous pouvez mettre des fichiers jusqu'à 1 Go (1024 Mo) en souscrivant au pack PRO.
      </p>
      <p>
        Sinon, n'hésitez pas à découper votre vidéo en plusieurs parties ou à la compresser.
      </p>
    </div>
  `,
  NOT_PERCENT: `
    <div class="content">
      <p>
        Le fichier dépasse 1024 Mo (trop volumineux).
      </p>
      <p>
        Les vidéos trop longues sont plus dures à suivre et se chargent plus lentement
        dans la page. Donc n'hésitez pas à découper votre vidéo en plusieurs parties.
      </p>
    </div>
  `,
};

export default {
  mixins: [ContentBlockWithUploadMixin],
  components: {
    ContentBlockVideoView,
    ContentBlockPartialTimecodes,
    VueSimpleEmbed,
    VideoTipsToggle,
  },
  static: {
    data: {
      path: '',
      srcType: 'embed',
      caption: '',
      timecodes: [],
    },
  },
  data: () => ({
    isForm: true,
  }),
  computed: {
    authStore() {
      return this.$store.getters['auth/store'];
    },
    isNotPercentPlan() {
      return this.$store.getters['auth/isNotPercentPlan'];
    },
    hasFormatLimitation() {
      return ['upload', 'url'].includes(this.newData.srcType);
    },
  },
  validations() {
    const path = { required };

    if (['url', 'embed'].includes(this.newData.srcType)) {
      path.url = url;
    }

    return {
      newData: {
        path,
        srcType: { required },
        timecodes: {
          $each: {
            time: {
              timecode,
            },
          },
        },
      },
    };
  },
  methods: {
    handleFile() {
      const size = this.isNotPercentPlan
        ? this.$constants.MAX_VIDEO_SIZE_PRO
        : this.$constants.MAX_VIDEO_SIZE;

      if (this.local.file.size > size) {
        this.local.file = null;
        this.local.progressValue = null;
        this.local.progressTotal = null;
        this.$refs.upload.$refs.input && (this.$refs.upload.$refs.input.value = null);
        this.$buefy.dialog.alert(messages[this.isNotPercentPlan ? 'NOT_PERCENT' : 'PERCENT']);
        return;
      }

      this.uploadFile(this.viewProps.store, 'video');
    },
  },
};
</script>

<template>
  <ContentBlockBase>
    <template #form>
      <div class="columns is-multiline">
        <div class="column is-12">
          <VideoTipsToggle />
        </div>
        <div class="column is-6">
          <figure class="p-3 has-background-grey bradius-8">
            <div v-if="newData.path">
              <vue-plyr v-if="newData.srcType != 'embed'">
                <video
                  class="is-block mx-auto bradius-8"
                  :src="`${localPath}#t=0.001`"
                />
              </vue-plyr>
              <VueSimpleEmbed v-else
                :url="`${localPath}#t=0.001`"
                parser="plyr"
                :attrs="{
                  width: '100%',
                  height: '360'
                }"
              />
            </div>
            <p v-else class="has-text-centered" style="line-height:172px">
              Pas de vidéo
            </p>
          </figure>
          <p v-show="newData.path">
            <b-button type="is-text has-text-danger" @click="newData.path = ''">
              Supprimer
            </b-button>
          </p>
          <template v-if="local.file">
            <b-progress
              v-if="!local.progressValue || local.progressValue < local.progressTotal"
              class="mb-2 mt-2"
              type="is-success"
              :value="local.progressValue"
              :max="local.progressTotal"
              format="percent"
              show-value
            />
            <p v-else-if="saveIsDisabled">
              Traitement du média...
            </p>
            <p class="is-size-8 mb-5">
              {{ local.file.name }} - {{ localFileSize }} Mo
            </p>
          </template>
        </div>
        <div class="column is-6">
          <b-field label="Source de la vidéo" v-bind="$getErrorProps($v.newData.srcType, ['required'])">
            <b-select v-model="newData.srcType" @input="newData.path = ''">
              <option value="embed">Vimeo ou YouTube</option>
              <option value="url">Lien direct (Amazon S3, serveur privé...)</option>
            </b-select>
          </b-field>
          <b-field v-if="newData.srcType == 'url'" label="Vidéo" v-bind="$getErrorProps($v.newData.path, ['required', 'url'])">
            <b-input v-model="newData.path" placeholder="Entrer le lien direct de la vidéo" />
          </b-field>
          <b-field v-else-if="newData.srcType == 'embed'" label="Vidéo" v-bind="$getErrorProps($v.newData.path, ['required', 'url'])">
            <b-input v-model="newData.path" placeholder="Entrer le lien Vimeo ou YouTube" />
          </b-field>
          <b-field v-else label="Vidéo" v-bind="$getErrorProps($v.newData.path, ['required'])">
            <b-upload ref="upload" accept="video/mp4" v-model="local.file" expanded @input="handleFile">
              <b-button tag="a" class="button" icon-left="file-video" outlined>
                Sélectionner une vidéo
              </b-button>
            </b-upload>
          </b-field>
          <p v-if="hasFormatLimitation" class="help is-success">
            Format .mp4
            <template v-if="newData.srcType == 'upload'">
              , max {{ isNotPercentPlan ? '1 Go (1024 Mo)' : '512 Mo' }}
            </template>
          </p>
          <b-field class="mt-6" label="Légende">
            <b-input v-model="newData.caption" />
          </b-field>
        </div>
        <div class="column is-12">
          <ContentBlockPartialTimecodes
            v-model="newData.timecodes"
            :v="$v.newData.timecodes"
          />
        </div>
      </div>
    </template>
    <template #view>
      <ContentBlockVideoView v-bind="viewProps" :data="data" />
    </template>
  </ContentBlockBase>
</template>
